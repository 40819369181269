import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AlertMessage from "../../components/Alert/AlertMessage";
import CustomViewField from "../../components/CustomViewField";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const types = {
  vacation: "Отпуск",
  day_off: "Отгул",
  sick_leave: "Больничный",
  overtime_work_days: "Сверхурочные часы",
  fired: "Увольнение",
  working_holidays: "Работа в праздники/выходные",
  promotion: "Поощрение",
};

const statuses = {
  new: { label: "Новое", color: "#414bb2" },
  approved: { label: "Согласовано", color: "#8fd14f" },
  rejected: { label: "Отклонено", color: "#f24726" },
  cancelled: { label: "Аннулировано", color: "#f24726" },
  recalled: { label: "Отозвано", color: "#f24726" },
  draft: { label: "Черновик", color: "#828282" },
};

const utcOffset = dayjs().utcOffset();

const PromotionView = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const [userBudget, setUserBudget] = useState(null);
  const { promotionId } = useParams();
  const navigate = useNavigate();

  const { setPromotion, setErrorAlertMessage, resetErrorAlertMessage } =
    useActions();

  const { promotion } = useSelector((state) => state.calendarReducer);

  useEffect(() => {
    resetErrorAlertMessage();
  }, [resetErrorAlertMessage]);

  const methods = useForm({
    mode: "onBlur",
    values: promotion,
  });

  const {
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (promotion?.status === "draft") {
        await $authHost.post(
          `/calendar_rules/promotion/${promotionId}/approve/`,
          data,
        );
      }

      if (promotion?.status === "approved") {
        await $authHost.post(
          `/calendar_rules/promotion/${promotionId}/cancel/`,
        );
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });

      if (
        e?.response?.data?.detail ===
        "You cannot approve or cancel promotion without amount"
      ) {
        setError("amount", {
          type: "custom",
        });
        setValue("amount", "");
        setErrorAlertMessage(
          "Ошибка: Количество дней отгула превышает допустимое значение, предусмотренное регламентами.",
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/schedule/promotions");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/schedule/promotions`);
  };

  const getUserBudget = useCallback(async (data) => {
    try {
      const response = await $authHost.get(
        `/calendar_rules/promotion/overwork_budget/`,
        {
          params: {
            user_id: data?.source_id,
          },
        },
      );
      setUserBudget(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${promotionId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [promotionId]);

  const getPromotion = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/calendar_rules/${promotionId}/`);
      const data = response.data;
      setPromotion(data);
      getUserBudget(data?.users[0]);
    } catch (e) {
      console.log(e);
    }
  }, [promotionId, setPromotion, getUserBudget]);

  useEffect(() => {
    Promise.all([getPromotion(), getHistory()]).then(() => setLoading(false));

    return () => {
      setPromotion({});
    };
  }, [getHistory, getPromotion, setPromotion]);

  return (
    <Card sx={{ mt: 2, p: 3 }}>
      {loading && <LoadingIndicator />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography sx={{ pb: 2 }} variant="h6">
                Информация:
              </Typography>
              <CustomViewField
                label="Тип заявки"
                text={types[promotion?.type]}
              />
              <CustomViewField
                label="Дата создания"
                text={dayjs(promotion?.created_at).format("DD.MM.YYYY")}
              />
              <CustomViewField
                label="Сотрудник"
                text={promotion?.users && promotion?.users[0]?.display_name}
              />
              <CustomViewField
                label="Отдел"
                text={promotion?.department?.name}
              />
              <CustomViewField
                label="Причина поощрения"
                text={promotion?.reason}
              />
              <CustomViewField
                label="Документ-основание"
                text={promotion?.supporting_document}
              />
              <CustomViewField label="Проект" text={promotion?.project?.code} />
              <CustomViewField
                label="Дата начала"
                text={dayjs(promotion?.start_date).format("DD.MM.YYYY")}
              />
              <CustomViewField
                label="Дата окончания"
                text={dayjs(promotion?.end_date).format("DD.MM.YYYY")}
              />
              <CustomViewField label="Коэффициент" text={promotion?.rate} />
              <CustomViewField
                label="Текст запроса"
                text={promotion?.request_text}
              />
              {promotion?.status !== "draft" && (
                <>
                  <CustomViewField
                    label="Количество отгулов"
                    text={promotion?.amount}
                  />
                  <CustomViewField
                    label="Комментарий"
                    text={promotion?.comment}
                  />
                </>
              )}
              <Divider sx={{ mb: 3 }} />
              {promotion?.status === "draft" && (
                <>
                  <CustomTextFieldNew
                    label="Количество отгулов"
                    name="amount"
                    required
                    type="number"
                  />
                  <CustomTextFieldNew
                    label="Комментарий"
                    multiline
                    name="comment"
                    required
                    rows={3}
                  />
                </>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={{ pb: 2 }} variant="h6">
                История:
              </Typography>
              <Stack>
                {history.map((i) => (
                  <List key={i.updated_at} sx={{ listStyleType: "disc" }}>
                    <ListItem
                      sx={[
                        { display: "list-item" },
                        { "&::marker": { color: statuses[i.status]?.color } },
                      ]}
                    >
                      <Stack>
                        <Typography variant="body2">
                          {dayjs(i.updated_at)
                            .add(utcOffset, "m")
                            .format("DD.MM.YYYY HH:mm")}
                        </Typography>
                        <Typography sx={{ pb: 1 }}>
                          {i.user_approved?.display_name}
                        </Typography>
                        <Typography sx={{ color: statuses[i.status]?.color }}>
                          {statuses[i.status]?.label}
                        </Typography>
                        {i.comment && (
                          <Typography sx={{ pt: 1 }}>
                            Комментарий: {i.comment}
                          </Typography>
                        )}
                      </Stack>
                    </ListItem>
                  </List>
                ))}
              </Stack>
            </Grid>
          </Grid>
          <AlertMessage />
          <Stack direction="row" spacing={2}>
            {cancelConfirm ? (
              <>
                <Button
                  onClick={() => setCancelConfirm(false)}
                  variant="contained"
                >
                  Нет
                </Button>
                <Button color="primary" type="submit" variant="text">
                  Да
                </Button>
              </>
            ) : (
              <>
                {promotion?.status === "draft" && (
                  <Button type="submit" variant="contained">
                    Сохранить
                  </Button>
                )}
                {promotion?.type === "promotion" &&
                  promotion?.status === "approved" && (
                    <>
                      <Button
                        onClick={() => setCancelConfirm(true)}
                        variant="contained"
                      >
                        Аннулировать
                      </Button>
                    </>
                  )}
                <Button color="primary" onClick={handleBack} variant="text">
                  Назад
                </Button>
              </>
            )}
          </Stack>
          {cancelConfirm && (
            <Typography sx={{ pt: 2 }}>
              {userBudget?.overwork_budget < promotion.amount
                ? "В выбранном заявлении количество поощрительных отгулов за переработки больше, чем остаток в бюджете переработок Сотрудника. Это значит, что Сотрудник уже израсходовал отгулы, согласованные данным Поощрением. Аннулирование Поощрения приведет к отрицательному балансу бюджета переработок. Все равно аннулировать?"
                : `На основании заявления сотруднику было начислено ${promotion.amount} отгулов в бюджет переработок. Аннулировать заявление и отменить начисление отгулов?`}
            </Typography>
          )}
        </form>
      </FormProvider>
    </Card>
  );
};

export default PromotionView;
